
  @import '../../../../styles/donut';

  .table-container-full-width {
    // Most of this CSS is in grid.scss
    // A rough approximation of the CSV upload and form submission sections on top and on bottom of the table
    .table-height {
      height: calc(100vh - 320px);
      max-height: calc(100vh - 320px);
    }
    .table-height.with-banner {
      height: calc(100vh - 580px);
      max-height: calc(100vh - 580px);
    }
  }
