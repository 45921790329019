
  @import '../../styles/donut';

  select.access {
    padding-left: 42px !important;
  }

  select.access + i {
    position: absolute;
    bottom: 12px;
    left: 16px;
  }

  #user-list {
    border-top: 2px solid $border;
    margin-top: 8px;
    border-bottom: 2px solid $border;
    margin-bottom: 8px;
  }
