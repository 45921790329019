// Colors

$white:           #FFFFFF;
$black:           #1A102E;

$purple:          #7F50FF;
$green:           #00D1BD;
$blue:            #49A8F6; // Watercooler card title & success header
$red:             #FF001F;
$pink:            #FF9EFF;
$light-pink:      #F9E9ED;
$cranberry:       #E3006F;
$gold:            #D7B82D;
$steel:           #8698BF;
$silver:          #BABABA;

$greenish:        #ABDCD9;
$yellow:          #F8E71C;
$orange:          #FF8F00;
$dark-peach:      #EA9860; // HRIS icon orange/brown

$medium-green:    #09A79A;
$medium-blue:     #4A90E2;
$gray-blue:       #5A7A84;

$error-red:       #C7254E;
$warning-yellow:  #DB931F;

$light-purple:    #B89EFF;

$dark-purple:     #380089;
$dark-green:      #0F7F74; // Celeb card title & success header
$dark-blue:       #2A62A4;
$dark-red:        #7F0010;
$dark-yellow:     #957339;
$dark-pink:       #B644B6;
$dark-orange:     #9E4A00;
$dark-cranberry:  #8E0052;
$dark-gold:       #936B01;
$dark-steel:      #687899;

$greenish-bg:     #ECF5E4;

$blue-bg:         #E4F2FE;
$green-bg:        #D9F8F5;
$purple-bg:       #EEEEFF;
$red-bg:          #FFE5E8;
$pink-bg:         #FFF2FF;
$orange-bg:       #FEE5C7;
$peach:           #EEBC99; // Intros success header
$yellow-bg:       #FFF3E7; // Intros
$yellow-lighter:  #FFFBF6; // Inactive Intros card

$purple-form-bg:  #F3F0FB;
$green-form-bg:   #EBF5F3; // Celebrations
$green-form-bg-lighter: #F5FAF9; // Inactive Celebrations card

$form-bg-light:   #F9F9FB; // Inactive Watercooler card
$purple-bg-light: #F6F6FF; // Watercooler

$purple-border:   #BFA7FF;
$yellow-border:   #FAEBCC;

$border:          #E9E9F0;
$dark-border:     #E1D9ED;
$form-bg:         #F4F3F8;

$background:      #F4F6F8;
$bg-light:        #FDFDFD;

$gray-purple:     #B3AABF;

$darker-gray:     #5B5B5B;
$dark-gray:       #645E71;
$gray:            #8C8595;
$light-gray:      #B3B3B3;
$very-light-gray: #E5E5E5;
$lightest-gray:   #FAFAFA;
$hover-bg:        #F9FAFB;
$icon-bg-gray:    #D8D8D8;

$digest-green:        #6dffa8;
$digest-green-fg:     #03d1bd;
$digest-blue:         #6de2ff;
$digest-orange:       #ff976d;
$digest-pink:         #ff95ff;
$digest-red:          #FF6DA8;
$digest-grellow:      #dcff00;
$digest-yellow:       #e6d822;
$digest-purple:       #946dff;
$digest-highlighter:  #dcff00;
$digest-blurple:      #0091ff;
$digest-deep-purple:  #0013ff;

$slack-primary:      #007a5a;
$slack-danger:       #b41541;

// hex-to-filter converter (if needed): https://codepen.io/sosuke/pen/Pjoqqp
// useful when paired with filter property { filter: $purple-svg; }

// Layout

$banner-height: 54px;

// Sizes

@mixin desktop {
  @media (min-width: 1168px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1168px) {
    @content;
  }
}

@mixin tablet-sm {
  @media (max-width: 896px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 720px) {
    @content;
  }
}

// Font Hosting

$font-host: 'https://d3h0uc480qkvgm.cloudfront.net';
