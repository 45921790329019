
  @import '../../../../styles/donut';

  .divider {
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 90%;
    height: 1px;
    background-color: $border;
  }
