
  @import '../../styles/donut';
  .grid {
    .separatorx2 {
      margin-left: -32px;
      width: calc(100% + 32px);
    }
  }
  .grid-wrapper.scrolls {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .grid {
      overflow: auto;

      .headers {
        flex: 0 0 auto;
        position: sticky;
        top: 0px;
      }
      .separatorx2 {
        position: absolute;
        left: 0px;
        right: 0px;
      }
      .separator-top {
        top: 26px;
      }
      .body {
        flex: 0 0 auto;
        padding-bottom: 2px;
      }
      .separator-bottom {
        bottom: 0px;
      }
    }
  }
  .grid-wrapper.scrolls:before {
    content: ' ';
    background-color: white;
    height: 28px;
    position: absolute;
    top: 0px; left: 0px; right: 0px;
  }
  .modal .grid-wrapper.scrolls {
    margin-right: -32px;
  }
  .row-handle {
    cursor: move;
  }
  .scroll-shadow-top {
    box-shadow: 0 4px 4px 1px rgba($black, 0.1);
  }
  .scroll-shadow-bottom {
    box-shadow: 0 -4px 4px 1px rgba($black, 0.1);
  }
