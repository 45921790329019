
  @import '../../styles/donut';

  .categories {
    display: flex;
    flex-direction: row;

    @include tablet-sm {
      flex-direction: column;
    }
  }
