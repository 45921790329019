
  @import '../../../../styles/donut';

  .library-sidebar {
    width: 400px;
    background-color: white;
    box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.2);
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 4500;
    transition: right 0.25s ease-in-out;

    > .scrolls {
      overflow-y: scroll;
      height: 100%;
    }
  }

  .library-sidebar.dragging {
    right: -352px;
    opacity: 0.5;
  }

  .library-sidebar.closed {
    right: -416px;
  }
