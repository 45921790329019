
  .trans-expand-item {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
  }

  .expand-enter-active,
  .expand-leave-active {
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    overflow: hidden;
  }

  .expand-slow-enter-active,
  .expand-slow-leave-active {
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    overflow: hidden;
  }

  .expand-enter,
  .expand-slow-enter,
  .expand-leave-to,
  .expand-slow-leave-to {
    height: 0;
    opacity: 0.0;
  }

  .no-animation {
    transition: none !important;
  }
