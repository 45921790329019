
  @import '../../styles/donut';

  .files li {
    display: inline-block;
    margin-right: 16px;
    width: 100%;
  }

  .files:empty {
    display: none;
  }

  form {
    width: auto;
    position: relative;

    input[type=file] {
      display: none;
    }
  }
