
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.15s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    position: absolute;
    opacity: 0.0;
  }
