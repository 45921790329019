
@import '../../styles/donut';
.preset-module {
  max-width: 438px;
  border-radius: 8px;
  border: 1px solid $purple;
  opacity: 1;
}
.preset-module.inactive {
  border: 1px solid $gray;
  opacity: 0.5;
}
