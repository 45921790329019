
  @import '../../styles/donut';

  .plan {
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
    display: inline-flex;
    color: white;
  }
  .plan.premium {
    background-color: $purple;
  }
  .plan.standard {
    background-color: $blue;
  }
  .plan.free {
    background-color: $green;
  }
