
  @import '../../../styles/donut';
  .clear-issue {
    opacity: 0;
    transition: opacity 0.1s ease-in, transform 0.1s ease-in;
    cursor: pointer;
  }
  .clear-issue:hover {
    filter: brightness(80%);
    transform: translateY(-2px);
  }
  .row:hover .clear-issue { opacity: 1; }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  .clear-issue-pending {
    pointer-events: none;
    opacity: 0.8;
    animation: spin 1s linear infinite;
  }
