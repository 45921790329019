
  @import '../../../styles/donut';

  .picker {
    width: 48px;
    height: 48px;
    border: 2px solid $border;
    border-radius: 2px;
    cursor: pointer;
  }

  .picker:hover {
    border: 2px solid $purple-border;
  }

  .picker, .no-color {
    background:
    linear-gradient(to top left,
    rgb(255,255,255) 0%,
    rgb(255,255,255) calc(50% - 0.8px),
    rgb(255,0,31) 50%,
    rgb(255,255,255) calc(50% + 0.8px),
    rgb(255,255,255) 100%),
  }
