
  @import '../styles/donut';

  .image-container {
    height: 180px;
    overflow: hidden;
    position: relative;
    background-color: $lightest-gray;
    border: 1px solid $border;
    cursor: pointer;
  }
  .image-container:hover {
    background-color: $lightest-gray;
    border: 2px solid $purple;
    border-radius: 5px;
  }
