
  @import '../../styles/donut';

  #big-donut {
    background-color: #ff90ff;
    border-radius: 100%;
    border: solid #380089 10vh;
    height: 100vh;
    width: 100vh;
    position: fixed;
    visibility: hidden;
    overflow: hidden;
    z-index: 1000;
    pointer-events: none;
  }

  #big-donut .eye {
    height: 24.5vh;
    width: 10vh;
    background-color: #380089;
    position: absolute;
    top: 25.5%;
    border-radius: 100vh;
  }

  #big-donut .left-eye {
    left: 11.5%;
  }

  #big-donut .right-eye {
    right: 11.5%;
  }

  #big-donut .mouth {
    height: 23.2vh;
    width: 34.3vh;
    border: solid 6.5vh #380089;
    background-color: white;
    position: absolute;
    border-radius: 0 0 100vh 100vh;
    left: 28.5%;
    top: 42%;
  }
