
  .team-memberships {
    overflow: hidden;
    width: calc(80% - 40px);
    scroll-behavior: smooth;
  }
  .scroll-arrow {
    cursor: pointer;
  }
  .fade-in {
    background: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1));
  }
  .fade-out {
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
  }
