
  .appear-enter-active,
  .appear-leave-active {
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .appear-enter,
  .appear-leave-to {
    top: -24px;
    opacity: 0.0;
  }
