
  @import '../../styles/donut';

  .type-badge {
    margin-right: -26px;
    margin-bottom: -26px;
    padding: 0px 8px;
    height: 32px;
    background-color: $border;
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
