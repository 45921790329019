
  .container {
    .count, .count-icon {
      transition: color 0.15s, opacity 0.15s;
    }

    > .progress-tracker.disabled {
      pointer-events: none;
      opacity: 0.65;
    }
  }
