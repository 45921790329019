<template lang="pug">
.card.f-ac.p-tx3.w-100p( :class='{ "purple-outline": isSelected }' )
  .h-x10.m-bx4
    cdn-svg.h-x10(:path="planDetails.image")
  .t-xxl.bold {{ planDetails.name }}

  donut-plans-pricing-details.m-bx2(:planDetails="planDetails")

  .f-v.f-ac.w-100p.p-hx2.h-68
    slot(name="actions")

  .f-v.f-ast.p-hx2.p-bx4
    .f-jc.t-sm.bold.dark-purple {{ planDetailsHeader }}
    .f.pos-rel.p-lx2.m-t15(
      v-for="item in planItems"
      :key="item"
    )
      .dot-sm.purple.pos-abs.left-2.top-6
      .t(v-html='item')

  slot(name="logos")

  slot(name="footer")

  slot(name="extra")
</template>

<script>
  import { mapState } from 'vuex';
  import CdnSvg from 'components/cdn-svg.vue';

  export default {
    components: {
      CdnSvg,
    },

    props: {
      planDetails: {
        type: Object,
        required: true,
      },
    },

    computed: {
      isSelected() {
        return this.currentPlan === this.planDetails.plan;
      },

      isPairing() {
        return this.planDetails.header.pairing != null;
      },

      isOnboarding() {
        return this.planDetails.header.onboarding != null;
      },

      planDetailsHeader() {
        if (this.isPairing) {
          return this.planDetails.header.pairing;
        } if (this.isOnboarding) {
          return this.planDetails.header.onboarding;
        }
      },

      planItems() {
        if (this.isPairing) {
          return this.planDetails.pairingItems;
        } if (this.isOnboarding) {
          return this.planDetails.onboardingItems;
        }
      },

      ...mapState([
        'page',
        'currentPlan',
      ]),
    },

    methods: {
      imagePath(category) {
        if (category === 'onboarding') {
          return 'nucleo/play-button';
        }
        return 'nucleo/handshake';
      },
    },
  };
</script>

<style scoped=true>

  .card { background-color: #FAFBFC !important; }

</style>
