
  @import '../../../../styles/donut';

  .option.selected {
    padding: 2px 4px;
    background-color: $purple;
    color: white;
    border-radius: 2px;
    margin-left: -4px;
  }
  .option.disabled { opacity: 0.65; }
