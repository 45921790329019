
  @import '../../../../styles/donut';

  .content {
    max-height: 80px;
    overflow: hidden;
  }

  .metadata {
    background-color: $hover-bg;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 8px;
    padding-left: 16px;
    padding-top: 8px;
    position: relative;
  }

  .target-bars + .metadata {
    margin-top: -4px;
    padding-top: 12px;
  }

  .hoverable {
    .show-on-hover {
      display: none;
    }

    &:hover {
      background: $hover-bg;

      .hide-on-hover {
        display: none;
      }

      .show-on-hover {
        display: unset;
      }
    }
  }
