
  @import '../../../../styles/donut';

  .day {
    background-color: $form-bg;
    border-radius: 4px;
    padding: 4px;

    .on-hover { visibility: hidden; }
  }

  .day:hover {
    .on-hover { visibility: visible; }
  }

  .day.past, .day.darkened  {
    background-color: rgba($form-bg, 0.65);
  }

  .day.special {
    position: relative;
  }

  .day.special:before {
    content: " ";
    position: absolute;
    top: 0px; left: 0px;
    width: 100%; height: 4px;
    background-color: $green;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .day.start-day {
    background-color: $green-form-bg;
    i.special-label { display: flex; }
    .special-label {
      font-weight: 700;
    }
  }

  .day.today {
    position: relative;
    background-color: $purple-form-bg;

    > .f-sb > .time {
      color: $purple;
      font-weight: 700;
    }
  }
  .day.today:before {
    content: " ";
    position: absolute;
    top: 0px; left: 0px;
    width: 100%; height: 4px;
    background-color: $purple;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .day.today:not(.start-day) {
    .special-label:nth-last-child(n+2) { display: none; }
    .special-label:last-child {
      font-size: 0px;
    }
    .special-label:last-child:before {
      color: $purple;
      content: "today";
      font-size: 18px;
    }
  }

  .day.droppable-hover {
    background-color: $purple-bg;
  }
