
  @import '../../../styles/donut';

  .toggle-button {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    padding: 0px 16px;
    background: $form-bg;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
