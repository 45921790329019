
  @import '../../styles/donut';

  .loading-dots {
    .dot {
      height: 6px;
      width: 6px;
      border-radius: 3px;
      background-color: $purple;
    }
    .dot:nth-child(1) {
      margin-right: 8px;
      animation: 1.2s bounce ease-in-out infinite;
    }
    .dot:nth-child(2) {
      animation: 1.2s bounce ease-in-out infinite;
      animation-delay: 0.4s;
    }
    .dot:nth-child(3) {
      margin-left: 8px;
      animation: 1.2s bounce ease-in-out infinite;
      animation-delay: 0.8s;
    }
  }

  @keyframes bounce {
    0%, 66% {
      transform: translateY(0px);
      background-color: $purple;
    }
    33% {
      transform: translateY(-3px);
      background-color: $dark-purple;
    }
  }
