
  .trans-list-item {
    transition: all 0.35s;
    transform-origin: top center;
  }
  .trans-list-enter, .trans-list-leave-to {
    opacity: 0;
    transform: scaleY(0);
    height: 0px !important;
  }
