
@import '../../styles/donut';
.flow-type {
  display: inline-block;
}
.flow-type.pill {
  border-radius: 200px;
  padding: 4px 12px;
}
.flow-type.clickable:hover {
  cursor: pointer;
  transform: translateY(-2px);
}
