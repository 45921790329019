
  @import '../../styles/donut';

  .error {
    display: flex;
    align-items: flex-start;
    margin-left: 16px;

    > i, .t { color: $red; }
    > .t { font-style: italic; }
  }
