
@import "../../styles/donut";

.learn-more-callout {
  background-color: transparentize($purple-bg, 0.05);
  border-radius: 4px;
  height: fit-content;
  left: 0;
  margin: 0 auto;
  max-width: 424px;
  min-width: 264px;
  padding: 24px 40px;
  position: absolute;
  right: 0;
  top: 23%;
  z-index: 2;
}
