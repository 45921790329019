
  @import '../../styles/donut';

  .sticky-info {
    position: fixed;
    width: 384px;

    @include tablet {
      width: 100%;
      position: inherit;
    }
  }

  .events {
    width: 456px;
    float: right;

    @include tablet {
      width: 100%;
      float: inherit;
    }
  }
