
  @import '../../styles/donut';

  .shimmer-box {
    background: #F2F2F2;
    display: flex;
    position: relative;
    height: 12px;
    box-sizing: border-box;
  }

  .shimmer-animation {
    width: 1000px;
    height: 100%;
    background: #F6F7F8;
    background-size: 1000px 100px;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f9f9f9 40%, #f9f9f9 100%);
    animation: shimmer 1s infinite linear;
  }

  @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
