<template lang="pug">
.f-v.b-b1
  .f.f-gap-6.p-b12.c-pointer.highlight-none(
    @click="toggleExpanded"
  )
    i.mi.dark-gray.animate-rotate(
      :class="{ 'animate-rotate-down': expanded }"
    ) arrow_right
    .t.bold.dark-gray.fc-g1
      slot(name="title")
    slot(name="titleRight")
  transition-expand
    .f(v-show="expanded")
      .fc-g1.p-b16.w-min-0
        slot(name="body")
</template>

<script>
  export default {
    props: {
      initExpanded: {
        type: Boolean,
        default: false,
      },
      dateGroup: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        expanded: this.initExpanded,
      };
    },

    methods: {
      toggleExpanded() {
        this.expanded = !this.expanded;
        this.$store.dispatch('onboardingSiteDisplay/track', {
          eventName: 'Toggled Group Expand',
          properties: {
            isTogglingOpen: this.expanded,
            date: this.dateGroup.date,
          },
        });
      },
    },
  };
</script>

<style scoped>
  .animate-rotate {
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
  }

  .animate-rotate-down {
    transform: rotate(90deg);
  }
</style>
