
  @import '../../../styles/donut';

  .category-header {
    position: relative;
    height: 72px;

    > .category-header-content {
      background-color: white;
      width: 100%;
      padding: 24px 0px;
      z-index: 1;
    }

    > .category-header-content.floating {
      position: fixed;
      top: 0px; left: calc(50% - 360px);
      width: 928px;
      border-bottom: 2px solid $border;
      padding-left: 32px;

      @media (max-width: 1216px) {
        left: 240px;
      }

      @include tablet {
        left: 0px;
        width: 100vw;
        padding: 24px calc(50% - 448px);
      }

      @media (max-width: 974px) {
        padding: 24px 32px;
      }
    }
  }

  .user + .user {
    border-top: 2px solid $border;
    box-sizing: border-box;
  }
