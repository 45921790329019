
  @import '../../styles/donut';

  .updated-wrapper {
    box-shadow: 0 0 0 10px transparent;
  }
  .updated-enter-active,
  .updated-leave-active {
    transition: all 0.7s ease-in-out;
    box-shadow: 0 0 0 10px transparent;
    background-color: transparent;
  }
  .updated-enter,
  .updated-leave-to {
    background-color: $green;
    box-shadow: 0 0 0 10px $green;
  }
