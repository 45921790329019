
@import '../../styles/donut';
.bundle-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  color: $white;

  .preset-list-scrollable {
    height: 116px;
    max-height: 116px;
    overflow-y: auto;

    .t {
      text-indent: -16px;
      padding-left: 16px;
      line-height: 16px;
    }
  }
}
.bundle-card-overlay.shade {
  background-color: $black;
  opacity: 0.7;
}
.bundle-card-overlay.shade.light {
  background-color: $white;
  opacity: 0.4;
}
