
  @import '../../styles/donut';

  .toast {
    position: absolute;
    min-width: 360px;
    max-width: 800px;
    padding: 10px 16px;
    top: 20px;
    background-color: $purple-bg;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba($black, 0.2);
    margin-left: 120px;

    i, .t { color: $purple; }
  }

  .toast.success {
    background-color: $green-bg;
    i, .t { color: $medium-green; }
  }

  .toast.error {
    background-color: $red-bg;
    i, .t { color: $red; }
  }
