
  @import '../../styles/donut';

  .wrapper-confetti {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 10000;
    pointer-events: none;
  }

  .purple-confetti {background-color: $purple;}
  .pink-confetti {background-color: $pink;}
  .green-confetti {background-color: $green;}
  .blue-confetti {background-color: $blue;}

  [class|="confetti"] {
    position: absolute;
  }
