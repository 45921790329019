
  @import '../../../styles/donut';

  .mask {
    text-align: center;

    > * {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
    > .t-lg {
      line-height: 32px;
      background-color: $icon-bg-gray;
    }
  }

  .membership-text-field {
    width: calc(100% - 40px)
  }

  .membership-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .megaphone {
    fill: $purple;
  }
