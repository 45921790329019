
  .module-container {
    display: grid !important;
    // 2 columns by default, when column gets below 250px it'll break into own row
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }


  .theme-title-color {
    color: v-bind("theme.title") !important;
  }
