
  @import '../../../styles/donut';

  .row.session {
    > .data.custom {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 32px;
      flex: 14 0 32px;

      > .separator {
        margin-left: 16px;
        width: calc(100% - 16px);
      }
    }

    > .data {
      > .f-ac {
        > button.disabled {
          pointer-events: auto;
        }
      }
    }

    .email {
      word-break: break-all;
    }
  }
