<template lang="pug">
.f-v
  .f
    svg-browser-top-left
    svg-browser-top-center.svg-browser-top-center(v-if="desktop")
    svg-browser-top-right
  .f.br-b8.b-h2.b-b2.of-h
    slot
</template>

<script>
  export default {
    props: {
      desktop: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style scoped>
  @media (max-width: 580px) {
    .svg-browser-top-center {
      display: none;
    }
  }
</style>
