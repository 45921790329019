
  .fullpage-enter-active,
  .fullpage-leave-active,
  .fullpage-reverse-enter-active,
  .fullpage-reverse-leave-active {
    transition: transform 700ms ease 0s;
  }

  .fullpage-enter,
  .fullpage-leave-to {
    transform: translateY(100vh);
  }

  .fullpage-reverse-enter,
  .fullpage-reverse-leave-to {
    transform: translateY(-100vh);
  }
