
  @import '../../../styles/donut';

  .status_text {
    padding: 6px;
    padding-right: 8px;
    border-radius: 4px;
  }

  .done {
    color: $green;
    background-color: $green-bg;
  }
  .waiting {
    color: $blue;
    background-color: $blue-bg;
  }
  .behind {
    color: $orange;
    background-color: $orange-bg;
  }
  .abandoned {
    color: $orange;
    background-color: $orange-bg;
  }
  .failed {
    color: $error-red;
    background-color: $light-pink;
  }
  .resolved {
    color: $dark-gray;
    background-color: $border;
  }
  .unsent {
    color: $gray;
    background-color: $background;
  }
  .omitted {
    color: $green;
    background-color: $green-bg;
  }
