
  @import '../../styles/donut';

  .loading {
    color: $gray !important;
    padding: 32px;
  }

  .failed {
    padding: 32px;
  }

  .loading-bay {
    height: 100%;
  }

  .loading-bay.loading:before {
    content: 'Loading...';
    font-size: 13px;
    line-height: 20px;
    color: $dark-gray;
    font-style: italic;
  }

  .loading-bay.loading:after, .loading-spinner:after {
    content: "autorenew";
    font-family: "Material Icons";
    font-size: 13px;
    float: right;
    color: $purple;
    animation: spin 2s linear infinite;
    text-transform: none;
  }

  .loading-bay.failed:before {
    content: 'Something went wrong';
    font-size: 13px;
    line-height: 20px;
    color: $red;
    font-style: italic;
  }

  .loading-bay.failed:after {
    content: "error";
    font-family: "Material Icons";
    font-size: 13px;
    line-height: 20px;
    float: right;
    color: $red;
    text-transform: none;
  }
