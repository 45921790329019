
  @import '../../../../styles/donut';

  .name {
    color: $purple;
  }

  a:hover {
    .name {
      color: $dark-purple;
      > .t {
        text-decoration: underline;
      }
    }
  }
