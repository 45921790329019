
  @import '../../../styles/donut';

  .specifics {
    position: absolute;
    top: -8px;
    width: 360px;
    background: $white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border: 2px solid $border;
    border-radius: 4px;
    z-index: 1;
  }

  .poll-option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
