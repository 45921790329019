
  @import '../../../styles/donut';

  .status-block {
    height: 20px;
    width: 20px;
    border-radius: 2px;
  }
  .status-block.done { background-color: $green; }
  .status-block.pending { background-color: $blue; }
  .status-block.flagged { background-color: $orange; }
  .status-block.failed { background-color: $error-red; }
  .status-block.resolved { background-color: $gray-purple; }
  .status-block.scheduled { background-color: $border; }

  .status-label.done { color: $green; }
  .status-label.pending { color: $blue; }
  .status-label.flagged { color: $orange; }
  .status-label.failed { color: $error-red; }
  .status-label.resolved { color: $dark-gray; }
  .status-label.scheduled { color: $gray; }
