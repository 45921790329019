
  @import '../../../styles/donut';

  .attachment-container {
    position: relative;
    width: calc(100% - 72px);
    margin-left: 56px;
    overflow: hidden;

    .attachment-preview {
      width: 100%;
      border: 1px solid rgba(29, 28, 29, 0.1);
      border-radius: 12px;
      padding: 12px;
      display: flex;

      .attachment-preview-icon {
        height: 36px;
        width: 36px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .attachment-preview-icon.blue { background-color: #1D9BD1; }
      .attachment-preview-icon.red  { background-color: #E01D5A; }
      .attachment-preview-icon.gray { background-color: #616061; }
      .attachment-preview-icon.green { background-color: #007a5a; }
      .attachment-preview-icon.orange { background-color: #df4e2b; }
    }

    .image-attachment, .video-attachment {
      width: 100%;
      max-width: 100%;
      max-height: 300px;
      border-radius: 4px;
      object-fit: contain;
      object-position: left center;
    }
  }
