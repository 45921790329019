
  @import '../../../styles/donut';

  .poll-answer {
    padding: 4px;
    border: 2px solid $border;
    border-radius: 2px;
    display: inline-block;
  }

  .status-footer {
    margin-top: 8px;
  }
