
  @import '../../styles/donut';

  .donut-filter {
    display: flex;
    position: relative;
    width: 100%;
    height: 40px;

    > .donut-filter-button {
      border-radius: 4px;
      background-color: $form-bg;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 16px;
      width: 100%;
      cursor: pointer;
      outline: none;
      border: none;
    }

    > .donut-filter-button:hover {
      background-color: $purple-bg;
    }

    > .donut-filter-options {
      position: absolute;
      top: 42px;
      left: 0px;
      right: 0px;
      background-color: $white;
      border: 2px solid $border;
      border-radius: 4px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
      z-index: 1;
      max-height: 200px;
      overflow-y: scroll;

      > .donut-filter-option {
        padding: 12px 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        outline: none;
        border: none;

        > .donut-filter-checkbox {
          height: 18px;
          width: 18px;
          border: 2px solid $border;
          margin-right: 8px;
          border-radius: 4px;
          background-color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      > .donut-filter-option.selected {
        > .donut-filter-checkbox {
          background-color: $purple;
          border-color: $purple;
        }
      }

      > .donut-filter-option:hover {
        background-color: $purple-bg;
      }
    }
  }
 