
  @import '../../styles/donut';

  .item {
    box-sizing: border-box;

    &.removed {
      opacity: .5;

      .remove {
        display: none;
      }
    }

    .preview {
      border: 1px solid $form-bg;
      border-bottom: 0;
    }
  }

  // Moving animations for reordering. Important to keep the transition time the same as the timeout in move()
  .item.moving-down {
    transition: transform 0.2s ease-in;
    transform: translateY(40px);
  }
  .item.moving-up {
    transition: transform 0.2s ease-in;
    transform: translateY(-40px);
  }
