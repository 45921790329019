
  .slide-ltr-enter-active,
  .slide-rtl-enter-active,
  .slide-ltr-leave-active,
  .slide-rtl-leave-active {
    transition: transform 0.25s ease-in-out, opacity 0.2s ease-in-out;
  }

  .slide-ltr-enter,
  .slide-ltr-enter,
  .slide-ltr-leave-to,
  .slide-rtl-leave-to, {
    position: absolute;
  }

  .slide-ltr-enter,
  .slide-rtl-leave-to {
    transform: translateX(100%);
    opacity: 0.0;
  }
  .slide-ltr-leave-to,
  .slide-rtl-enter {
    transform: translateX(-100%);
    opacity: 0.0;
  }
