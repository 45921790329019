
  @import '../../../styles/donut';

  .overview-session-chart {
    height: 32px;
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    background-color: $border;
    width: 100%;

    .done { background-color: $green; }
    .pending { background-color: $blue; }
    .flagged { background-color: $orange; }
    .failed { background-color: $error-red; }
    .resolved { background-color: $gray-purple; }
    .unsent { background-color: $border; }
  }
